import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../layout/Spinner";
import { useContext } from "react";
import storeContext from "../context/storeContext";
import { FaNairaSign } from "react-icons/fa6";
import { FaLongArrowAltRight } from "react-icons/fa";
import { MdCallReceived } from "react-icons/md";
import { LuSend } from "react-icons/lu";
import { CiBank } from "react-icons/ci";
import Modal from "react-modal";
import { IoMdCloseCircle } from "react-icons/io";
import { motion } from "framer-motion";
import Transactions from "./Transactions";

function Wallet() {
  const {
    isLoading,
    setIsLoading,
    isAuth,
    walletBalance,
    fetchBalance,
    setDesktopBarOpen,
    setNavbarOpen,
    wallet,
    setWallet,
    userProfile,
    bankDetail,
    fetchBankAccount,
    fetchProfile,
  } = useContext(storeContext);

  //navigate
  const navigate = useNavigate();

  //for modal
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  function selectWallet(e) {
    setWallet(e.target.value);
  }

  //check if transaction is empty
  const isEmpty = Object.keys(bankDetail).length === 0;

  //check if transaction is empty
  const isProfileEmpty = Object.keys(userProfile).length === 0;

  const country = userProfile.country;

  //run this code when page loads
  async function runAtStartup() {
    await fetchProfile();
    if (userProfile.country === "NG" && !isProfileEmpty) {
      fetchBankAccount();
    }
    fetchBalance(wallet);
  }

  useEffect(() => {
    setIsLoading(true);
    if (isAuth) {
      runAtStartup();
    }
    if (!isAuth) {
      return navigate("/login");
    }

    setDesktopBarOpen(false);
    setNavbarOpen(false);
    setIsLoading(false);
  }, [wallet, country]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="px-5 xl:px-12 py-6  items-center   lg:ml-10">
        {userProfile.bvnVerificationStatus !== "true" &&
        userProfile.country === "NG" ? (
          <Link
            className="btn mx-3 text-white bg-secondary my-3"
            to="/recipient"
          >
            Verify BVN <LuSend />
          </Link>
        ) : null}
        {userProfile.verificationStatus === "false" ? (
          <Link
            className="btn mx-3 text-primary bg-yellow-600 my-3"
            to="/userverification"
          >
            Start document verification <LuSend />
          </Link>
        ) : null}
        <div className="col-span-6 sm:col-span-3 my-4">
          <label
            htmlFor="Wallet"
            className="block text-sm font-medium text-white pb-2"
          >
            {" "}
            Select a Wallet
          </label>
          <div className="flex">
            <img
              className="w-10 h-10 rounded-full"
              src={
                wallet === "usd"
                  ? "https://cdn.britannica.com/33/4833-004-828A9A84/Flag-United-States-of-America.jpg"
                  : "https://upload.wikimedia.org/wikipedia/commons/thumb/7/79/Flag_of_Nigeria.svg/1200px-Flag_of_Nigeria.svg.png"
              }
              alt="Rounded avatar"
            />
            <select
              id="Wallet"
              required
              value={wallet}
              onChange={selectWallet}
              className="h-10 rounded border-gray-300 text-sm w-32 border ml-1"
            >
              <option value="ngn">NGN</option>
              <option value="usd">USD</option>
            </select>
          </div>
        </div>
        <article className="flex items-end justify-between rounded-lg border border-gray-100 bg-white p-6  w-80">
          <div className="flex items-center gap-4">
            <span className="hidden rounded-full bg-gray-100 p-2 text-gray-600 sm:block">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>
            </span>

            <div>
              <div>
                <p className="text-sm text-gray-500">Wallet Balance</p>

                <p className="text-2xl font-medium text-gray-900">
                  {wallet === "ngn" ? (
                    <>₦{Number(walletBalance).toLocaleString()} NGN</>
                  ) : (
                    <>${Number(walletBalance).toLocaleString()} USD</>
                  )}
                </p>
              </div>
              {userProfile.country === "NG" && wallet === "ngn" ? (
                <div className="mt-3">
                  <p className="text-sm text-gray-800">
                    {isEmpty === true ? null : bankDetail.msg.bankAccountName}
                  </p>
                  <p className="text-sm text-gray-800">
                    {isEmpty === true ? null : bankDetail.msg.bank}
                  </p>
                  <p className="text-sm text-gray-500">
                    Account No:{" "}
                    <span className="text-gray-800">
                      {isEmpty === true
                        ? null
                        : bankDetail.msg.bankAccountNumber}
                    </span>
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        </article>
        <div className="mt-3">
          {wallet === "ngn" ? (
            <>
              {userProfile.country === "NG" && wallet === "ngn" ? (
                <div className="btn hover:bg-secondary" onClick={openModal}>
                  Deposit <MdCallReceived className="text-lg" />
                </div>
              ) : null}

              <Modal
                isOpen={isOpen}
                className="border w-80 mx-auto mt-16"
                onRequestClose={closeModal}
              >
                <div
                  role="alert"
                  className="rounded border-s-4 border-black bg-yellow-50 p-4"
                >
                  <strong className="block font-medium text-black py-3">
                    {" "}
                    Your Dedicated Account Number To Fund Your Naira Account{" "}
                  </strong>

                  <p className="mb-6 text-base text-secondary">
                    Send money from any bank in Nigeria to credit your NairaDom
                    wallet instantly
                  </p>

                  <div className="bg-black p-3">
                    <p className="mt-2 text-base ">
                      Bank Name:{" "}
                      <span className="text-primary">
                        {isEmpty === true ? null : bankDetail.msg.bank}
                      </span>
                    </p>
                    <p className="mt-2 text-base ">
                      Bank Account Number:{" "}
                      <span className="text-primary">
                        {isEmpty === true
                          ? null
                          : bankDetail.msg.bankAccountNumber}
                      </span>
                    </p>
                    <p className="mt-2 text-base ">
                      Account Name:{" "}
                      <span className="text-primary">
                        {isEmpty === true
                          ? null
                          : bankDetail.msg.bankAccountName}
                      </span>
                    </p>
                  </div>
                  <div
                    className="btn mt-3 hover:bg-secondary"
                    onClick={closeModal}
                  >
                    Close <IoMdCloseCircle className="text-lg" />
                  </div>
                </div>
              </Modal>

              <Link
                className="btn mx-3 text-white bg-secondary my-3"
                to="/apptransfer"
              >
                Transfer to NairaDOM user <LuSend />
              </Link>

              <Link
                className="btn bg-primary hover:bg-primary-accent hover:text-secondary-content text-black"
                to="/banktransfer"
              >
                Withdraw to bank
                <CiBank className="text-xl" />
              </Link>
              <Link
                className="btn hover:primary-accent bg-yellow-600 text-white mx-3 my-3"
                to="/usdwalletdeposit"
              >
                Buy dollars <MdCallReceived className="text-lg" />
              </Link>
            </>
          ) : (
            <>
              <Link className="btn hover:bg-secondary" to="/usdwalletdeposit">
                Buy dollars with Naira <MdCallReceived className="text-lg" />
              </Link>

              {/* <Link
                className="btn mx-3 text-white bg-secondary my-3"
                to="/investmentplans"
              >
                Invest <LuSend />
              </Link> */}

              <Link
                className="btn bg-primary hover:bg-primary-accent hover:text-secondary-content text-black"
                to="/usdwalletwithdraw"
              >
                Withdraw to Naira wallet <CiBank className="text-xl" />
              </Link>

              <Link
                className="btn mx-3 text-white bg-yellow-600 my-3 "
                to="/cryptodeposit"
              >
                Fund with crypto <MdCallReceived />
              </Link>
            </>
          )}
        </div>
      </div>

      <Transactions currency={wallet} />
    </>
  );
}
export default Wallet;
